import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';
import { Header2} from '../../components';
import { Internationalization } from '@syncfusion/ej2-base';       
import { lineCustomSeries, LinePrimaryXAxis2, LinePrimaryXAxisALIMSnew, LinePrimaryYAxisAll , LinePrimaryXAxisAllDS} from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {Aws_controller} from '../../components';




const systParms = ['GAS_INJECTION_SWIVEL','FLOWLINE_CIRCULATION_SWIVEL','HPU_1','GAS_LIFT_SWIVEL',
                  'GAS_EXPORT_SWIVEL','PRODUCTION_2_SWIVEL','TEST_SWIVEL','HPU_2','PRODUCTION_1_SWIVEL',
                  'HVESR_US_LVEOSR','DEPRESSURIZATION_2_SWIVEL','DEPRESSURIZATION_1_SWIVEL','LEAK_RECOVERY',
                  'WATER_INJECTION_SWIVEL'];

const paramParms = ['Leak Tank Pressure','Pressure OBS secondary bottom','Swivel Torque (mean value)',
                    'Pressure Downstream','Leak Tank Level','Pressure OBS inlet','Heat Tracing Upstream',
                    'Pressure OBS primary bottom','Oil Tank Level','HVESR Swivel Torque (mean value)',
                    'Pressure OBS primary top','Pressure OBS secondary top','Pressure Upstream','Heat Tracing',
                    'LVEOSR Swivel Torque (mean value)',  'Heat Tracing Downstream'];
const paramParms2 = ['Pressure OBS primary top','Pressure OBS primary bottom','Swivel torque (mean value)'];
// AWS PULL //
const statementorig = "SELECT timestamp as ts, parameter as par,_system as syst, mean as bar ,min as low, max as high FROM dev.public.ds_swivel WHERE date >= DATEADD(WEEK, -1, '2022-05-31') ORDER BY timestamp ASC;";
const statement = "SELECT timestamp as ts, parameter as par,value as bar FROM dev.public.swivel WHERE (timestamp >= DATEADD(MONTH, -1, GETDATE()) AND value != 0) ORDER BY timestamp ASC;";
const thedata = await Aws_controller(statement)
//////////////
const LineChartSwivelDynamic = ({sysss}) => {
  
  //const [syst2, setSyst2] = useState('GAS_INJECTION_SWIVEL');
  //const syst = 'TEST_SWIVEL'
  const parmfilter = (dasyst, daparm ,dadata) => dadata.filter(dasyst => dasyst.SYSTEM === dasyst).filter(dasystparm => dasystparm.PARAMETER === daparm);
  const testtest = sysss 
  const [parameter, setParameter] = useState('Pressure OBS primary top');
  const { currentMode, currentColor } = useStateContext();
  const [users, setUsers] = useState([])
  const [info, setInfo] = useState([])
    
  //.. AWS PULL ..//
  //Set AWS data
  useEffect(() =>{setInfo(thedata)}, []);
  //const result = info.length
  const infoo = info //.filter(dasyst => dasyst[2].stringValue.includes(sysss));
  //console.log('The Outputswivel:',infoo)
  let points=[]
  for (let i = 0; i < infoo.length; i++) {
    let point = {};
    point["TIMESTAMP"] = infoo[i][0].stringValue;
    point["PARAMETER"] = infoo[i][1].stringValue;
    //point["SYSTEM"] = infoo[i][2].stringValue;
    point["MEAN"] = infoo[i][2].doubleValue;
    //point["MIN"] = infoo[i][4].doubleValue;
    //point["MAX"] = infoo[i][5].doubleValue;
    points.push(point);}
  //console.log('The Outputswivy:', points)
  //////////////////
  const encodedValue = encodeURIComponent('GAS_INJECTION_SWIVEL')
  // const fetchData = () => {
  //   fetch(`http://localhost:59476/api/Swivel`)
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data => {
  //       setUsers(data)
  //     })
  // }
  // useEffect(() => {
  //   fetchData()
  // }, [])
  //dataSource: points.filter(dasyst => dasyst.SYSTEM.includes(sysss)).filter(dasystparm => dasystparm.PARAMETER === parameter),
  
  const lineCustomSeries2 = [
    { dataSource: points.filter(dasyst => dasyst).filter(dasystparm => dasystparm.PARAMETER === parameter),
      xName: 'TIMESTAMP',
      yName: 'MEAN',
      name: 'Mean',
      width: '2',
      fill: 'green',
      marker: { visible: true, width: 3, height: 3,fill:'black',
      border: { width: 2, color: 'grey' }},
      type: 'Line' },
      {/*{ dataSource: points.filter(dasyst => dasyst.SYSTEM.includes(sysss)).filter(dasystparm => dasystparm.PARAMETER === parameter),
      xName: 'TIMESTAMP',
      yName: 'MIN',
      name: 'Min',
      width: '3',
      fill: 'bg-white',
      marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'Red',
              border: { width: 2, color: 'Red' }},
      type: 'Line' },
      { dataSource: points.filter(dasyst => dasyst.SYSTEM.includes(sysss)).filter(dasystparm => dasystparm.PARAMETER === parameter),
      xName: 'TIMESTAMP',
      yName: 'MAX',
      name: 'Max',
      width: '0',
      color: 'green',
      marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'green',
              border: { width: 2, color: 'green' }},
      type: 'Line' },*/}
  ];
  const tooltipRender = (args) => {
    let intl = new Internationalization();
    let formattedDate = intl.formatDate(new Date(args.point.x), { skeleton: 'yMd' }); // Formats as '230922'
    let formattedTime = intl.formatDate(new Date(args.point.x), { skeleton: 'Hms' }); // Formats as '143512'
    let XformattedString = intl.formatDate(new Date(args.point.x), { skeleton: 'Hms' });
    let YformattedString = intl.formatNumber(args.point.y,{format: '###.##' });
    args.text =  `${formattedDate} ${formattedTime} : ${YformattedString}`; 
  };
  return (
    <div>
        <div className='bg-white dark:bg-secondary-dark-bg flex flex-wrap w-full pl-2 pr-2 justify-center rounded-xl'>
          <div className='flex flex-wrap w-3/12 justify-left items-center rounded-2xl pl-0'>
            <Header2 category="30-day Trending" title="High Resolution" thacolor={currentColor} />
          </div>
          <div className='flex flex-wrap w-9/12  align-middle rounded-2xl pr-0'>
            <div className="w-full flex items-center ">
              
              <div className="w-full m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
                <DropDownListComponent id="ddlelement2" 
                dataSource={paramParms2} 
                placeholder= 'Pressure OBS primary top'
                onChange={e => {setParameter(e.target.value)}}
                />
              </div>
            </div>
            
          </div>
      </div>
      <br />
      <ChartComponent
        id="line-chart"
        height="420px"
        primaryXAxis={LinePrimaryXAxisAllDS}
        primaryYAxis={LinePrimaryYAxisAll}
        chartArea={{ border: { width: 0 } }}
        // Tooltip custom format
        tooltipRender={tooltipRender}
        tooltip={{
            enable: true,
            format: '${series.name} : ${point.y} <br> Time : ${point.x}',
        }}
        enableMouseWheelZooming
        zoomSettings={
          {enableMouseWheelZooming: true,
          enablePan: true,
          enableScrollbar: true}
      }
        background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
        legendSettings={{ background: 'white' }}
      >
        <Inject services={[LineSeries, DateTime, Legend, Tooltip, Zoom ]} />
        <SeriesCollectionDirective>
          {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
};

export default LineChartSwivelDynamic