import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';

import { lineCustomSeries, LinePrimaryXAxisGREASE, LinePrimaryYAxisAll } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { Header, Header2} from '../../components';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import {Aws_controller} from '../../components';;
// AWS PULL //
const statement = "SELECT * FROM dev.public.grease order by sample_date ASC;";
const thedata = await Aws_controller(statement)
//////////////

const tpmsParms2 = ['IRON','COPPER','TIN','LEAD','CHROMIUM','NICKEL','ALUMINUM','TITANIUM','SILVER','CALCIUM','MAGNESIUM','ZINC','PHOSPHORUS','BARIUM','MOLYBDENUM',
                    'ANTIMONY','H2O','SILICON','SODIUM','BORON','POTASSIUM','VANADIUM','HOURS_MILES','LITHIUM','PQ_INDEX'];
const locs = ['FWD', 'STBD','Drum','AFT','Port']

const LineChartGrease = () => {
    const [param, setParam] = useState('COPPER');
    const [location, setLocation] = useState('FWD');
    const { currentColor } = useStateContext();
    const { currentMode } = useStateContext();
    const encodedValue = encodeURIComponent('FPSO Heading')
    const [info, setInfo] = useState(thedata)
    //.. AWS PULL ..//
    //Set AWS data
    useEffect(() =>{setInfo(thedata)}, [thedata]);
    //const result = info.length
    //('The Output:', info)
    let points=[]
    for (let i = 0; i < info.length; i++) {
      let point = {};
      point["POSITION"] = info[i][1].stringValue;
      point["TIMESTAMP"] = info[i][4].stringValue;
      point['IRON'] = info[i][5].doubleValue;
      point['COPPER'] = info[i][6].doubleValue;
      point['TIN'] = info[i][7].doubleValue;
      point['LEAD'] = info[i][8].doubleValue;
      point['CHROMIUM'] = info[i][9].doubleValue;
      point['NICKEL'] = info[i][10].doubleValue;
      point['ALUMINUM'] = info[i][11].doubleValue;
      point['TITANIUM'] = info[i][12].doubleValue;
      point['SILVER'] = info[i][13].doubleValue;
      point['CALCIUM'] = info[i][14].doubleValue;
      point['MAGNESIUM'] = info[i][15].doubleValue;
      point['ZINC'] = info[i][16].doubleValue;
      point['PHOSPHORUS'] = info[i][17].doubleValue;
      point['BARIUM'] = info[i][18].doubleValue;
      point['MOLYBDENUM'] = info[i][19].doubleValue;
      point['ANTIMONY'] = info[i][20].doubleValue;
      point['H2O'] = info[i][21].doubleValue;
      point['SILICON'] = info[i][22].doubleValue;
      point['SODIUM'] = info[i][23].doubleValue;
      point['BORON'] = info[i][24].doubleValue;
      point['POTASSIUM'] = info[i][25].doubleValue;
      point['VANADIUM'] = info[i][26].doubleValue;
      point['HOURS_MILES'] = info[i][27].doubleValue;
      point['LITHIUM'] = info[i][28].doubleValue;
      point['PQ_INDEX'] = info[i][29].doubleValue;

      points.push(point);}
    //console.log('The OutputGREASE:', points)
    //////////////////
    const lineCustomSeries2 = [
        { dataSource: points.filter(para => para.POSITION.includes(location)),
          xName: 'TIMESTAMP',
          yName: param,
          name: 'Mean',
          width: '2',
          fill: 'grey',
          marker: { visible: true, width: 3, height: 3,fill:'black',
          border: { width: 2, color: 'grey' }},
          type: 'Line' },
      ];

    return (
        <div>
          <div className='bg-white dark:bg-secondary-dark-bg flex flex-wrap w-full pl-2 pr-2 justify-center rounded-xl'>
              <div className='flex flex-wrap w-3/12 justify-left items-center rounded-2xl pl-0'>
                <Header2 category="30-day Trending" title="Hourly Resolution" thacolor={currentColor} />
              </div>
              <div className='flex flex-wrap w-9/12  align-middle rounded-2xl pr-0'>
                <div className="w-full flex items-center ">
                  
                  <div className="w-full m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
                    <DropDownListComponent id="ddlelement" 
                    dataSource={locs} 
                    placeholder='FWD'
                    onChange={e => setLocation(e.target.value)}
                    />
                  </div>
                  <div className="w-full m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
                    <DropDownListComponent id="ddlelement" 
                    dataSource={tpmsParms2} 
                    placeholder='COPPER'
                    onChange={e => setParam(e.target.value)}
                    />
                  </div>
                </div>
                
              </div>
          </div>
          
          <div className="flex m-0 md:m-2 mt-24 p-2 text-xl rounded-2xl justify-center 
                        bg-white dark:bg-black
                      border-slate-600 dark:border-white border-4 ">
            <div className='w-full'>
              <ChartComponent
                id="grease-chart"
                height="420px"
                primaryXAxis={LinePrimaryXAxisGREASE}
                primaryYAxis={LinePrimaryYAxisAll}
                chartArea={{ border: { width: 0 } }}
                tooltip={{ enable: true }}
                enableMouseWheelZooming
                zoomSettings={
                  {enableMouseWheelZooming: true,
                  enablePan: true,
                  enableScrollbar: true}
              }
                background={currentMode === 'Dark' ? 'bg-secondary-dark-bg ' : '#fff'}
                legendSettings={{ background: 'white' }}
              >
                <Inject services={[LineSeries, DateTime, Legend, Tooltip, Zoom ]} />
                <SeriesCollectionDirective>
                  {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </div>
      </div>
      );
    };

export default LineChartGrease