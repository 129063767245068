/* at 1:32:36  in video*/
import React from 'react';
import SecureRoute from './SecureRoute';
import { BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { FiSettings } from 'react-icons/fi'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Navbar, Sidebar, ThemeSettings} from './components';
import { HomeBase, Calendar, Employees, Stacked, Pyramid, Customers,
Line, Area, Bar, Pie, Financial, ColorPicker, ColorMapping, Editor,
ALIMS, ALIMS_TS, StationKeeping, HeadingRotation, AnchorLegs,TPMS,
SwivelSystem, RotationMonitoring,LubeSystem, SlipRings, ICSS, Grease} from './pages'

import {CurrentReport, ArchiveReports} from './pages'

import { Depressurization1,Depressurization2, FlowlineCirculation , GasExport,
GasInjection, GasLift, HPU1, HPU2, HVESR_US_LVEOSR, LeakRecovery, 
Production1, Production2, TestSwivel, WaterInjection, BlankTestPage} from './pages'

import { useStateContext } from './contexts/ContextProvider';

import Home2 from './Home2';
import Login2 from './Login2';



import './App.css'
import './Grids.css'

//require('dotenv').config()
import Home from './Home';


const oktaAuth = new OktaAuth({
  issuer: 'https://modec.okta.com',
  clientId: '0oafep4n8r8TPiJ985d7',
  //issuer: 'https://modec.okta.com/oauth2/default',
  //clientId: '0oafep4n8r8TPiJ985d7',
  //issuer: process.env.REACT_APP_OKTA_ISSUER_URL,
  //clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',//+ '/homebase',
});
//console.log('showing:', oktaAuth.redirectUri)
const App = () => {
  
  //asd
  const restoreOriginalUri =  async (_oktaAuth, originalUri) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const navigate = useNavigate()
    // navigate(toRelativeUrl(originalUri || '/', window.location.origin), {replace: true});
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  }
  const { blockedMenu, setBlockedMenu,activeMenu,setActiveMenu, themeSettings, setThemeSettings, currentColor, currentMode} = useStateContext();

  return (
    

    
    <div className={currentMode === 'Dark' ? 'dark': ''}>
      
      <Router>
      
      <div className='flex relative dark:bg-main-dark-bg'>
        <div className= "fixed right-4 bottom-4" style={{ zIndex: '1000'}}>
        <TooltipComponent content="settings" position='Top'>
          <button type ='button'
          className='text-3xl p-3
          hover:drop-shadow-xl
          hover:bg-light-gray text-white'
          onClick={() => setThemeSettings(true)}
          style ={{ background: currentColor,
          borderRadius: '50%'}}>
            <FiSettings/>
          </button>
        </TooltipComponent>
        </div>

        {activeMenu & blockedMenu ? (
          <div className='w-72 fixed sidebar
          dark:bg-secondary-dark-bg 
          bg-white'>
            <Sidebar />
          </div>
        ) : (
          <div className='w-0 dark:bg-secondary-dark-bg'>
            <Sidebar />
          </div>
        )}

          <div
            className={
              `dark:bg-main-dark-bg  bg-main-bg 
              min-h-screen w-full ${activeMenu & blockedMenu ?
              'md:ml-72' : 'flex-2'}`
            }>
              <div className='fixed md:static
              bg-main-bg dark:bg-main-dark-bg
              navbar w-full'>
                <Navbar />
            </div>
        
        
        <div>
          {themeSettings && <ThemeSettings />}
          
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
              {/*console.log('showing:', oktaAuth.redirectUri)*/}
              <Routes>
                <Route path="/" exact element={<Home2 />} />
                <Route path='/login' element={<Login2 />} />
                <Route path="/implicit/callback" element={<LoginCallback/>} />
                  
                  <Route path="/protected" element={
                    <SecureRoute>
                      <HomeBase /> 
                    </SecureRoute>
                } />
                {/* Protected Pages  */}
                {/* Dashboard  */}
                <Route path="/homebase" element={<SecureRoute><HomeBase /></SecureRoute> } y />

                {/*<Route path="/homebase" element={(<HomeBase />)}y /> */}

                {/* Pages  */}
                <Route path="/tpms" element={ <SecureRoute> <TPMS /> </SecureRoute> }/>

                <Route path="/alims" element={<SecureRoute><ALIMS /></SecureRoute>}/>

                <Route path="/swivel-monitoring" element={<SecureRoute> <SwivelSystem /> </SecureRoute> }/>

                <Route path="/rotation-monitoring" element={<SecureRoute> <RotationMonitoring /> </SecureRoute>}/>

                <Route path="/lubrication-system" element={<SecureRoute> <LubeSystem /> </SecureRoute>} />

                <Route path="/slip-rings" element={<SecureRoute><SlipRings /></SecureRoute>} />

                <Route path="/icss" element={<SecureRoute><ICSS /></SecureRoute>} />

                <Route path="/grease" element={<SecureRoute><Grease /></SecureRoute> } />
                {/*<Route path="/grease" element={(<Grease />)} />*/}
                
                
                


                
                <Route path="/alims-timeseries" element={<SecureRoute><ALIMS_TS /></SecureRoute> } />
                <Route path="/station-keeping" element={<SecureRoute><StationKeeping /></SecureRoute> } />
                <Route path="/heading-and-rotation" element={<SecureRoute><HeadingRotation /></SecureRoute> } />
                <Route path="/anchor-legs"  element={<SecureRoute><AnchorLegs /></SecureRoute> } />
                <Route path="/employees" element={<Employees />} />
                <Route path="/customers" element={<Customers />} />

                {/* Swivel Stacks */}
                <Route path="/depressurization1" element={<SecureRoute><Depressurization1 /></SecureRoute> } />
                <Route path="/depressurization2" element={<SecureRoute><Depressurization2 /></SecureRoute> } />
                <Route path="/flowline-circulation" element={<SecureRoute><FlowlineCirculation /></SecureRoute> } />
                <Route path="/gas-export" element={<SecureRoute><GasExport /></SecureRoute> } />
                <Route path="/gas-injection" element={<SecureRoute><GasInjection /></SecureRoute> } />
                <Route path="/gas-lift" element={<SecureRoute><GasLift /></SecureRoute> } />
                <Route path="/hpu1" element={<SecureRoute><HPU1 /></SecureRoute> } />
                <Route path="/hpu2" element={<SecureRoute><HPU2 /></SecureRoute> } />

                <Route path="/hvesr-us-lveosr" element={<SecureRoute><HVESR_US_LVEOSR /></SecureRoute> } />
                <Route path="/leak-recovery" element={<SecureRoute><LeakRecovery /></SecureRoute> } />
                <Route path="/production1" element={<SecureRoute><Production1 /></SecureRoute> } />
                {/* <Route path="/production1" element={(<Production1 />)} /> */}
                <Route path="/production2" element={<SecureRoute><Production2 /></SecureRoute> } />
                <Route path="/test-swivel" element={<SecureRoute><TestSwivel /></SecureRoute> } />
                <Route path="/water-injection" element={<SecureRoute><WaterInjection /></SecureRoute> } />

                {/* Monthlys */}
                {/*<Route path="/current-report" element={<CurrentReport />} />
                {/*<Route path="/archive-reports" element={<ArchiveReports />} />*/}
                <Route path="/current-report" element={<SecureRoute><CurrentReport /></SecureRoute> } />
                <Route path="/archive-reports" element={<SecureRoute><ArchiveReports /></SecureRoute> } />
                <Route path="/blanktestpage" element={<SecureRoute><BlankTestPage /></SecureRoute> } />


                {/* Apps  */}
                <Route path="/editor" element={<SecureRoute><Editor /></SecureRoute> } />
                <Route path="/calendar" element={<SecureRoute><Calendar /></SecureRoute> } />
                <Route path="/color-picker" element={<SecureRoute><ColorPicker /></SecureRoute> } />


                {/* Charts  */}
                <Route path="/line" element={<SecureRoute><Line /></SecureRoute> } />
                <Route path="/area" element={<SecureRoute><Area /></SecureRoute> } />
                <Route path="/bar" element={<SecureRoute><Bar /></SecureRoute> } />
                <Route path="/pie" element={<SecureRoute><Pie /></SecureRoute> } />
                <Route path="/financial" element={<SecureRoute><Financial /></SecureRoute> } />
                <Route path="/color-mapping" element={<SecureRoute><ColorMapping /></SecureRoute> } />
                <Route path="/pyramid" element={<SecureRoute><Pyramid /></SecureRoute> } />
                <Route path="/stacked" element={<SecureRoute><Stacked /></SecureRoute> } />
              </Routes>
            </Security>
        </div>
        </div>
      </div>
      
      </Router>
      
    </div>
    
  );
};

export default App

{/*  */}
// {/* Pages  */}
// <Route path="/tpms" element={<TPMS />} />
// <Route path="/alims" element={<ALIMS />} />
// <Route path="/swivel-monitoring" element={<SwivelSystem />} />
// <Route path="/rotation-monitoring" element={<RotationMonitoring />} />
// <Route path="/lubrication-system" element={<LubeSystem />} />
// <Route path="/slip-rings" element={<SlipRings />} />
// <Route path="/icss" element={<ICSS />} />
// <Route path="/grease" element={<Grease />} />

// <Route path="/alims-timeseries" element={<ALIMS_TS />} />
// <Route path="/station-keeping" element={<StationKeeping />} />
// <Route path="/heading-and-rotation" element={<HeadingRotation />} />
// <Route path="/anchor-legs" element={<AnchorLegs />} />
// <Route path="/employees" element={<Employees />} />
// <Route path="/customers" element={<Customers />} />

// {/* Swivel Stacks */}
// <Route path="/depressurization1" element={<Depressurization1 />} />
// <Route path="/depressurization2" element={<Depressurization2 />} />
// <Route path="/flowline-circulation" element={<FlowlineCirculation />} />
// <Route path="/gas-export" element={<GasExport />} />
// <Route path="/gas-injection" element={<GasInjection />} />
// <Route path="/gas-lift" element={<GasLift />} />
// <Route path="/hpu1" element={<HPU1 />} />
// <Route path="/hpu2" element={<HPU2 />} />
// <Route path="/hvesr-us-lveosr" element={<HVESR_US_LVEOSR />} />
// <Route path="/leak-recovery" element={<LeakRecovery />} />
// <Route path="/production1" element={<Production1 />} />
// <Route path="/production2" element={<Production2 />} />
// <Route path="/test-swivel" element={<TestSwivel />} />
// <Route path="/water-injection" element={<WaterInjection />} />


// {/* Monthlys */}
// <Route path="/current-report" element={<CurrentReport />} />
// <Route path="/archive-reports" element={<ArchiveReports />} />
// <Route path="/blanktestpage" element={< BlankTestPage />} />

// {/* Apps  */}
// <Route path="/editor" element={<Editor />} />
// <Route path="/calendar" element={<Calendar />} />
// <Route path="/color-picker" element={<ColorPicker />} />

// {/* Charts  */}
// <Route path="/line" element={<Line />} />
// <Route path="/area" element={<Area />} />
// <Route path="/bar" element={<Bar />} />
// <Route path="/pie" element={<Pie />} />
// <Route path="/financial" element={<Financial />} />
// <Route path="/color-mapping" element={<ColorMapping />} />
// <Route path="/pyramid" element={<Pyramid />} />
// <Route path="/stacked" element={<Stacked />} />