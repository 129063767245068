import React, {useEffect,useState} from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, HistogramSeries } from '@syncfusion/ej2-react-charts';

import {Aws_controller} from '../../components';

// AWS PULL //
const statement = "SELECT * FROM dev.public.ds_tpms WHERE date >= DATEADD(MONTH, -1, GETDATE()) and parameter = 'FPSO Heading' ORDER BY timestamp ASC;";//current_date
const thedata = await Aws_controller(statement)
//////////////
//asd

const TpmsHistoHeading = (dacolor) => {
    const [info, setInfo] = useState(thedata)
    const { currentMode } = useStateContext();
    const { currentColor } = useStateContext();
    
    //.. AWS PULL ..//
    //Set AWS data
    useEffect(() =>{setInfo(thedata)}, [thedata]);
    //const result = info.length
    //console.log('The OutputHisto:', info)
    let points=[]
    for (let i = 0; i < info.length; i++) {
      let point = {};   
      if (info[i][6].doubleValue){
        point["TIMESTAMP"] = info[i][3].stringValue;
        point["PARAMATER"] = info[i][4].stringValue;
        point["MEAN"] = info[i][6].doubleValue;
        points.push(point);
      } else{
        point["TIMESTAMP"] = info[i][3].stringValue;
        point["PARAMATER"] = info[i][4].stringValue;
        point["MEAN"] = null
      }
      }
      
    //console.log('The Output5:', points)
    //////////////////
const lineCustomSeries2 = [
    { dataSource: points,
        xName: 'TIMESTAMP',
        yName: 'MEAN',
        name: 'Mean',
        width: '2',
        fill: currentColor,
        showNormalDistribution: false,
        columnWidth:0.9,
        binInterval: 15,
        type: 'Histogram' },
]
const thecolor= dacolor
const primaryxAxis = { majorGridLines: { width: 0 }, minimum: 0, maximum: 360 };
//const primaryxAxis ={ AxisModel : { valueType: 'Category'}};
const primaryyAxis = {  minimum: 0 , majorTickLines: { width: 0 } ,lineStyle: { width: 0 } };
const legendSettings = { visible: false };
const tooltipsettings = { enable: true };
const marker = { dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#7f7f7f' } } };
  return (
    <div >
        <ChartComponent 
          id='charts'
          primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis}
          tooltip={tooltipsettings} legendSettings={legendSettings}
          title = '30-day Distribution' 
          titleStyle={{
            textAlignment: 'Far',
            color: currentColor,
            size: '20px',
            fontWeight: '600'
          }}
          width='100%'
          background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
          >
            <Inject services={[HistogramSeries, Legend, Tooltip, Category, DataLabel]}/>
            <SeriesCollectionDirective>
              {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
            </SeriesCollectionDirective>
        </ChartComponent>
    </div>  
  )
}

export default TpmsHistoHeading

//              {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
