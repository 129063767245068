import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';
import { Internationalization } from '@syncfusion/ej2-base';
import { lineCustomSeries, LinePrimaryXAxisGREASE, LinePrimaryYAxisH2O } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { Header, Header2} from '..';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import {Aws_controller} from '..';;
// AWS PULL //
const statement = "SELECT * FROM dev.public.grease order by sample_date ASC;";
const thedata = await Aws_controller(statement)
//////////////

const LineChartGrease_Cluster = ({theid, loc, dtitle}) => {
    const [param, setParam] = useState(1);
    const { currentColor } = useStateContext();
    const { currentMode } = useStateContext();
    const encodedValue = encodeURIComponent(1)
    const [info, setInfo] = useState(thedata)
    //.. AWS PULL ..//
    //Set AWS data
    useEffect(() =>{setInfo(thedata)}, [thedata]);
    //const result = info.length
    //console.log('The Output:', info)
    const did = theid
    let Wearpoints=[]
    let Addpoints=[]
    let H2opoints=[]
    let PQpoints=[]

    //WEAR METALS
    for (let i = 0; i < info.length; i++) {
      let Wpoint = {};
      Wpoint["LOCATION"] = info[i][1].stringValue;
      Wpoint["TIMESTAMP"] = info[i][4].stringValue;
      Wpoint['IRON'] = info[i][5].doubleValue;
      Wpoint['COPPER'] = info[i][6].doubleValue;
      Wpoint['TIN'] = info[i][7].doubleValue;
      Wpoint['LEAD'] = info[i][8].doubleValue;
      Wpoint['CHROMIUM'] = info[i][9].doubleValue;
      Wpoint['NICKEL'] = info[i][10].doubleValue;
      Wpoint['ALUMINUM'] = info[i][11].doubleValue;
      Wpoint['TITANIUM'] = info[i][12].doubleValue;
      Wpoint['SILVER'] = info[i][13].doubleValue;
      Wearpoints.push(Wpoint);}
    //console.log('The OutputWear:', Wearpoints)
    ////////////////// 
    const wears = Wearpoints.filter(para => para.LOCATION.includes(loc));
    //console.log('The OutputWearsNew2:', wears)
    //////////////////
    //ADDITIVE PARTICLES
    for (let i = 0; i < info.length; i++) {
      let Apoint = {};
      Apoint["LOCATION"] = info[i][1].stringValue;
      Apoint["TIMESTAMP"] = info[i][4].stringValue;
      Apoint['CALCIUM'] = info[i][14].doubleValue;
      Apoint['MAGNESIUM'] = info[i][15].doubleValue;
      Apoint['ZINC'] = info[i][16].doubleValue;
      Apoint['PHOSPHORUS'] = info[i][17].doubleValue;
      Apoint['BARIUM'] = info[i][18].doubleValue;
      Apoint['MOLYBDENUM'] = info[i][19].doubleValue;
      Apoint['ANTIMONY'] = info[i][20].doubleValue;
      Apoint['LITHIUM'] = info[i][28].longValue;
      Addpoints.push(Apoint);}
    //console.log('The OutputADD:', Addpoints)
    ////////////////// 
    const adds = Addpoints.filter(para => para.LOCATION.includes(loc));
    //console.log('The OutputAddsNew2:', adds)

    //H20
    for (let i = 0; i < info.length; i++) {
      let Hpoint = {};
      Hpoint["LOCATION"] = info[i][1].stringValue;
      Hpoint["TIMESTAMP"] = info[i][4].stringValue;
      Hpoint['WATER'] = info[i][21].doubleValue || 0;
      H2opoints.push(Hpoint);}
    //console.log('The OutputH2o:', H2opoints)
    ////////////////// 
    const h2os = H2opoints.filter(para => para.LOCATION.includes(loc));
    //console.log('The OutputH20sNew2:', h2os)
    //PQ Index
    for (let i = 0; i < info.length; i++) {
      let Ppoint = {};
      Ppoint["LOCATION"] = info[i][1].stringValue;
      Ppoint["TIMESTAMP"] = info[i][4].stringValue;
      Ppoint['PQ'] = info[i][29].longValue || 0;
      PQpoints.push(Ppoint);}
    //console.log('The OutputPQ:', PQpoints)
    ////////////////// 
    const pqs = PQpoints.filter(para => para.LOCATION.includes(loc));
    //console.log('The OutputPQsNew2:', pqs)
    //////////////////
    //////////////////

    const setTracker= (idd) => {
      if (idd === 'H2O') {
        const tracker = [
          { dataSource: h2os,
          xName: 'TIMESTAMP',
          yName: 'WATER',
          name: 'H2O',
          width: '2',
          fill: 'green',
          marker: { visible: true, width: 3, height: 3,fill:'green',
          border: { width: 2, color: 'green' }},
          type: 'Line' },
      ];
      return tracker
    } else if (idd === 'PQ'){
      const tracker = [
        { dataSource: pqs,
        xName: 'TIMESTAMP',
        yName: 'PQ',
        name: 'PQ',
        width: '2',
        fill: 'green',
        marker: { visible: true, width: 3, height: 3,fill:'green',
        border: { width: 2, color: 'green' }},
        type: 'Line' },
    ];
    return tracker
  } else if (idd === 'Additive'){
    const tracker = [
      { dataSource: adds,
      xName: 'TIMESTAMP',
      yName: 'CALCIUM',
      name: 'Calcium',
      width: '2',
      fill: 'green',
      marker: { visible: true, width: 3, height: 3,fill:'green',
      border: { width: 2, color: 'green' }},
      type: 'Line' },
  ];
    return tracker
    } else if (idd === 'Wear'){
      const tracker = [
        { dataSource: wears,
        xName: 'TIMESTAMP',
        yName: 'IRON',
        name: 'Iron',
        width: '2',
        fill: 'green',
        marker: { visible: true, width: 3, height: 3,fill:'green',
        border: { width: 2, color: 'green' }},
        type: 'Line' },
    ];
    return tracker
    };};
    const lineCustomSeries = setTracker(theid)

    // const lineCustomSeries = [
    //     { dataSource: PQpoints.filter(para => para.LOCATION.includes("AFT")),
    //       xName: 'TIMESTAMP',
    //       yName: 'PQ',
    //       name: 'AFT',
    //       width: '2',
    //       fill: 'red',
    //       marker: { visible: true, width: 3, height: 3,fill:'black',
    //       border: { width: 2, color: 'red' }},
    //       type: 'Line' },
    // ];
    //console.log('Outputh20:' , lineCustomSeries1)
    //////////////////////
    const tooltipRender = (args) => {
        let intl = new Internationalization();
        let XformattedString = intl.formatDate(new Date(args.point.x), { skeleton: 'yMd' });
        let YformattedString = intl.formatNumber(args.point.y,{format: '###.##' });
        args.text =  `${XformattedString} : ${YformattedString}`; 
    };
    //console.log('check:',lineCustomSeries)

  return (
    

    
     <div className="m-0 p-2 text-xl rounded-lg justify-center 
                 bg-white dark:bg-black
                 border-slate-600 dark:border-slate-400 border-4 ">

            <ChartComponent
              id={did}
              width='100%' 
              height='56.25%'
              title={dtitle}
              titleStyle={{
                color: 'white',
                //size: '10px',
                fontWeight: 'Normal'
              }}
              primaryXAxis={LinePrimaryXAxisGREASE}
              primaryYAxis={LinePrimaryYAxisH2O}
              chartArea={{ border: { width: 0 } }}
              tooltipRender={tooltipRender}
              tooltip={{ enable: true }}
              enableMouseWheelZooming
              zoomSettings={
                  {enableMouseWheelZooming: true,
                  enablePan: true,
                  enableScrollbar: true}
              }
              background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
              //legendSettings={{ background: 'white' }}
              >
              <Inject services={[LineSeries, DateTime,  Tooltip, Zoom ]} />
              <SeriesCollectionDirective>
                  {lineCustomSeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
                  
              </SeriesCollectionDirective>
            </ChartComponent>
        </div>  
     // </div>

);
};

export default LineChartGrease_Cluster