import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom, 
Point} from '@syncfusion/ej2-react-charts';
import { Internationalization } from '@syncfusion/ej2-base';
import { lineCustomSeries, LinePrimaryXAxis2, LinePrimaryXAxisAll, LinePrimaryYAxisAll,LinePrimaryXAxisAllDS } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {Aws_controller} from '../../components';


// AWS PULL //
const statement = `SELECT * FROM dev.public.ds_hvesr WHERE timestamp >= DATEADD(MONTH, -1, (SELECT  max(date) FROM dev.public.ds_hvesr)) UNION SELECT * FROM dev.public.ds_lveosr WHERE timestamp >= DATEADD(MONTH, -1, (SELECT  max(date) FROM dev.public.ds_lveosr)) ORDER BY timestamp ASC`;
const thedata = await Aws_controller(statement)


const systParms = ['LVEOSR','HVESR'];

const paramParms = ['Acceleration X','Acceleration Y','Acceleration Z','Ambient Light',
                    'Angular Position','Humidity','Temperature'];

const LineChartSlipRings = () => {
  const [syst, setSyst] = useState('HVESR');
  const [info, setInfo] = useState(thedata)
  const [parameter, setParameter] = useState('Acceleration X');
  const { currentMode } = useStateContext();
  //const encodedValue = encodeURIComponent('GAS_INJECTION_SWIVEL')

    //    //.. AWS PULL ..//
    // //Set AWS data
    useEffect(() =>{setInfo(thedata)}, [thedata]);
    //console.log('The OutputSlip1:', info)
    let datapoints = [];// asdasd
    for (let i = 0; i < info.length; i++) {
        let point = {};
        point["TS"] = new Date(info[i][3].stringValue);
        point["SYSTEM"] = info[i][4].stringValue;
        point["PARAMETER"] = info[i][5].stringValue;
        point["MEAN"] = info[i][7].doubleValue;
        point["MIN"] = info[i][8].doubleValue;
        point["MAX"] = info[i][9].doubleValue;
        // push point
        datapoints.push(point);
        }
      //console.log('The OutputSlip2:',  datapoints)
  
      //////////////////

// NEED TO PUT RANGE PADDING IN
  const lineCustomSeries2 = [
    { dataSource: datapoints.filter(dasyst => dasyst.SYSTEM.includes(syst)).filter(dasystparm => dasystparm.PARAMETER === parameter),
      xName: 'TS',
      yName: 'MEAN',
      name: 'Mean',
      width: '2',
      fill: 'grey',
      marker: { visible: true, width: 3, height: 3,fill:'black',
      border: { width: 2, color: 'grey' }},
      type: 'Line' },
      { dataSource: datapoints.filter(dasyst => dasyst.SYSTEM.includes(syst)).filter(dasystparm => dasystparm.PARAMETER === parameter),
      xName: 'TS',
      yName: 'MIN',
      name: 'Min',
      width: '3',
      fill: 'bg-white',
      marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'Red',
              border: { width: 2, color: 'Red' }},
      type: 'Line' },
      { dataSource: datapoints.filter(dasyst => dasyst.SYSTEM.includes(syst)).filter(dasystparm => dasystparm.PARAMETER === parameter),
      xName: 'TS',
      yName: 'MAX',
      name: 'Max',
      width: '0',
      color: 'green',
      marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'green',
              border: { width: 2, color: 'green' }},
      type: 'Line' },
  ];
  const tooltipRender = (args) => {
    let intl = new Internationalization();
    let formattedDate = intl.formatDate(new Date(args.point.x), { skeleton: 'yMd' }); // Formats as '230922'
    let formattedTime = intl.formatDate(new Date(args.point.x), { skeleton: 'Hms' }); // Formats as '143512'
    let XformattedString = intl.formatDate(new Date(args.point.x), { skeleton: 'Hms' });
    let YformattedString = intl.formatNumber(args.point.y,{format: '###.##' });
    args.text =  `${formattedDate} ${formattedTime} : ${YformattedString}`; 
  };
  return (
    <div>
      <div className="m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
        <DropDownListComponent id="ddlelement" 
        dataSource={systParms} 
        placeholder= 'HVESR'
        onChange={e => {setSyst(e.target.value)}}
        />
      </div>
      <div className="m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
        <DropDownListComponent id="ddlelement2" 
        dataSource={paramParms} 
        placeholder= 'Acceleration X'
        onChange={e => {setParameter(e.target.value)}}
        />
      </div>
      <br />
      <div className="flex m-0 md:m-2 mt-24 p-2 text-xl rounded-2xl justify-center 
                    bg-white dark:bg-black
                     border-slate-600 dark:border-white border-4 ">
          <div className='w-full'>
            <ChartComponent
              id="line-chart"
              height="420px"
              primaryXAxis={LinePrimaryXAxisAllDS}
              primaryYAxis={LinePrimaryYAxisAll}
              chartArea={{ border: { width: 0 } }}
              // Tooltip custom format
              tooltipRender={tooltipRender}
              tooltip={{
                  enable: true,
                  format: '${series.name} : ${point.y} <br> Time : ${point.x}',
              }}
              enableMouseWheelZooming
              zoomSettings={
                {enableMouseWheelZooming: true,
                enablePan: true,
                enableScrollbar: true}
            }
              background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
              legendSettings={{ background: 'white' }}
            >
              <Inject services={[LineSeries, DateTime, Legend, Tooltip, Zoom ]} />
              <SeriesCollectionDirective>
                {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item}/>)}
              </SeriesCollectionDirective>
            </ChartComponent>
          </div>

      </div>
  </div>
  );
};

export default LineChartSlipRings;