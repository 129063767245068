import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';
import { Internationalization } from '@syncfusion/ej2-base';
import { Header2} from '../../components';
import { lineCustomSeries, LinePrimaryXAxisAll, LinePrimaryYAxisAlimsChart, LinePrimaryXAxisALIMSnew } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {Aws_controller} from '../../components';

const ALIMSLegs = [1,2,3,4,5,6,7,8,9];
const statement = 'SELECT * FROM dev.public.ds_alims WHERE mean is not null ORDER BY "timestamp" DESC';
const dadata = await Aws_controller(statement)
//console.log('The OutputAlimssss:', dadata)
const LineChartALIMS = () => {
    const [param, setParam] = useState(1);
    const { currentColor } = useStateContext();
    const { currentMode } = useStateContext();
    const [info, setInfo] = useState(dadata)

    //Set AWS datasdf
    useEffect(() =>{setInfo(dadata)}, [dadata]);
    //const result = info.length
    //console.log('The OutputAlims:', info)
    let finalData=[]
    for (let i = 0; i < info.length; i++) {
      let point = {};
      point["SERIAL"] = info[i][0].stringValue;
      point["TIMESTAMP"] = info[i][3].stringValue;
      point["ANCHOR_LEG"] = info[i][4].longValue;
      point["MEAN"] = info[i][6].doubleValue;
      point["MIN"] = info[i][7].doubleValue;
      point["MAX"] = info[i][8].doubleValue;
      finalData.push(point);}
    //console.log('The Output2:', finalData)


      // OLD FETCH STATEMENT
    // const fetchData = () => {
    //   fetch(`http://localhost:59476/api/ALIMS`)
    //   .then(response => {
    //       return response.json()
    //       })
    //       .then(data => {
    //           setUsers(data)
    //       })
    //   }

    //   useEffect(() => {
    //       fetchData()
    //   }, [])
    const lineCustomSeries2 = [
        { dataSource: finalData.filter((para) => para.ANCHOR_LEG == param),
          xName: 'TIMESTAMP',
          yName: 'MEAN',
          name: 'Mean',
          width: '2',
          fill: 'grey',
          marker: { visible: true, width: 3, height: 3,fill:'black',
          border: { width: 2, color: 'grey' }},
          type: 'Line' },
          { dataSource: finalData.filter((para) => para.ANCHOR_LEG == param ),
          xName: 'TIMESTAMP',
          yName: 'MIN',
          name: 'Min',
          width: '3',
          fill: 'bg-white',
          marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'Red',
                  border: { width: 2, color: 'Red' }},
          type: 'Line' },
          { dataSource: finalData.filter((para) => para.ANCHOR_LEG == param),
          xName: 'TIMESTAMP',
          yName: 'MAX',
          name: 'Max',
          width: '0',
          color: 'green',
          marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'green',
                  border: { width: 2, color: 'green' }},
          type: 'Line' },
      ];    
      //console.log('data:', finalData.filter((para) => para.ANCHOR_LEG == param))
      const tooltipRender = (args) => {
        let intl = new Internationalization();
        let XformattedString = intl.formatDate(new Date(args.point.x), { skeleton: 'Hms' });
        let YformattedString = intl.formatNumber(args.point.y,{format: '###.##' });
        args.text =  `${XformattedString} : ${YformattedString}\u00b0`; 
    };
  return (
    <div>
      <div className='bg-white dark:bg-secondary-dark-bg flex flex-wrap w-full pl-2 pr-2 justify-center rounded-xl'>
          <div className='flex flex-wrap w-3/12 justify-left items-center rounded-2xl pl-0'>
            <Header2 category="30-day Trending" title="Hourly Resolution " thacolor={currentColor}  />
          </div>
          <div className='flex flex-wrap w-9/12  align-middle rounded-2xl pr-0'>
            <div className="w-full flex items-center ">
              <div className='flex flex-wrap w-11/12  align-middle justify-end rounded-2xl pr-0'>
                Select Anchor Leg:
              </div>
              <div className="flex flex-wrap w-1/12 m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
                <DropDownListComponent id="ddlelement" 
                dataSource={ALIMSLegs} 
                placeholder={1}
                onChange={e => setParam(e.target.value)}
                />
              </div>
            </div>
            
          </div>
      </div>

      <br />
      <div className="flex m-0 md:m-2 mt-24 p-2 text-xl rounded-2xl justify-center 
                    bg-white dark:bg-black
                  border-slate-600 dark:border-white border-4 ">
        <div className='w-full'>
          <ChartComponent
            id="line-chart"
            height="420px"
            primaryXAxis={LinePrimaryXAxisALIMSnew}
            primaryYAxis={LinePrimaryYAxisAlimsChart}
            chartArea={{ border: { width: 0 } }}
            tooltipRender={tooltipRender}
            tooltip={{ enable: true ,
                        format: "<b>${point.x} : ${point.y}</b>"}}

            enableMouseWheelZooming
            zoomSettings={
              {enableMouseWheelZooming: true,
              enablePan: true,
              enableScrollbar: true}
          }
            background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
            legendSettings={{ background: 'white' }}
          >
            <Inject services={[LineSeries, DateTime, Legend, Tooltip, Zoom ]} />
            <SeriesCollectionDirective>
              {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
      </div>
    </div>
  );
};

export default LineChartALIMS