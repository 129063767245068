import React , {useEffect,useState} from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,
Zoom } from '@syncfusion/ej2-react-charts';

import { lineCustomSeries, LinePrimaryXAxisAll, LinePrimaryYAxisAll } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {Aws_controller} from '../../components';

const lubeParms = ['GREASE LEVEL MAIN RESERVOIR','NUMBER OF STARTS GREASE PUMP',
                  'PRESSURE END GREASE LINE A1 AND A2','PRESSURE PUMP GREASE LINE 1',
                  'PRESSURE PUMP GREASE LINE 2','RUNNING HOURS OF GREASE PUMP'];


// AWS PULL //
const statement = "SELECT timestamp as ts, parameter as par, mean as bar ,min as low, max as high FROM dev.public.ds_lube WHERE date >= DATEADD(MONTH, -1, (SELECT  max(date) FROM dev.public.ds_lube)) ORDER BY timestamp ASC;";
const thedata = await Aws_controller(statement);
//////////////

const LineChartLube = () => {
    const [param, setParam] = useState('GREASE LEVEL MAIN RESERVOIR');
    const { currentMode } = useStateContext();
    const [info, setInfo] = useState(thedata)


    //.. AWS PULL ..//
    //Set AWS data
    useEffect(() =>{setInfo(thedata)}, [thedata]);
    //const result = info.length
   // console.log('The Output:', info)
   let lines=[];
    for (let i = 0; i < info.length; i++) {
      let point = {};
      point["TIMESTAMP"] = info[i][0].stringValue;
      point["PARAMETER"] = info[i][1].stringValue;
      point["MEAN"] = info[i][2].doubleValue;
      point["MIN"] = info[i][3].doubleValue;
      point["MAX"] = info[i][4].doubleValue;
    lines.push(point);}
    //console.log('The OutputLUBE:', lines)


//////////////

    const lineCustomSeries2 = [
        { dataSource: lines.filter(para => para.PARAMETER.includes(param)),
        xName: 'TIMESTAMP',
        yName: 'MEAN',
        name: 'Mean',
        width: '2',
        fill: 'grey',
        marker: { visible: true, width: 3, height: 3,fill:'black',
        border: { width: 2, color: 'grey' }},
        type: 'Line' },
        { dataSource: lines.filter(para => para.PARAMETER.includes(param)),
        xName: 'TIMESTAMP',
        yName: 'MIN',
        name: 'Min',
        width: '3',
        fill: 'bg-white',
        marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'Red',
                border: { width: 2, color: 'Red' }},
        type: 'Line' },
        { dataSource: lines.filter(para => para.PARAMETER.includes(param)),
        xName: 'TIMESTAMP',
        yName: 'MAX',
        name: 'Max',
        width: '0',
        color: 'green',
        marker: { visible: true, width: 7, height: 5, shape: 'Line',fill:'green',
                border: { width: 2, color: 'green' }},
        type: 'Line' },
    ];

    return (
        <div>
            <div className="m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
                <DropDownListComponent id="ddlelement" 
                dataSource={lubeParms} 
                placeholder='GREASE LEVEL MAIN RESERVOIR'
                onChange={e => setParam(e.target.value)}
                />
            </div>
            <br />
            <div className="flex m-0 md:m-2 mt-24 p-2 text-xl rounded-2xl justify-center 
                    bg-white dark:bg-black
                  border-slate-600 dark:border-white border-4 ">
                <div className='w-full'>
                    <ChartComponent
                    id="line-chart"
                    height="420px"
                    primaryXAxis={LinePrimaryXAxisAll}
                    primaryYAxis={LinePrimaryYAxisAll}
                    chartArea={{ border: { width: 0 } }}
                    tooltip={{ enable: true }}
                    enableMouseWheelZooming
                    zoomSettings={
                        {enableMouseWheelZooming: true,
                        enablePan: true,
                        enableScrollbar: true}
                    }
                    background={currentMode === 'Dark' ? 'bg-secondary-dark-bg' : '#fff'}
                    legendSettings={{ background: 'white' }}
                    >
                    <Inject services={[LineSeries, DateTime, Legend, Tooltip, Zoom ]} />
                    <SeriesCollectionDirective>
                        {lineCustomSeries2.map((item, index) => <SeriesDirective key={index} {...item} />)}
                    </SeriesCollectionDirective>
                    </ChartComponent>
                </div>
            </div>
    </div>
    );
};

export default LineChartLube