import React, {useState, useEffect} from "react";
import AWS, { Config } from 'aws-sdk';
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import {getSignedUrl} from "@aws-sdk/s3-request-presigner";

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

const createPresignedUrlWithClient = (fignum) => {
    const REGIONN = "us-east-2";
    const BUCKET = "sofec-sangomar-dropoff";
    const KEY = `data/monthly assessments/current/${fignum}.png`;
    const creds = new AWS.Credentials(accessKeyId, secretAccessKey,null)
    const client = new S3Client({credentials: creds,
      region:"us-east-2"});
    //console.log(client.config);
    const command = new GetObjectCommand({ Bucket: BUCKET, Key: KEY });
  return getSignedUrl(client, command, { expiresIn: 3600 });
};
const createPresignedUrlWithClient2 = (fignum) => {
  const REGIONN = "us-east-2";
  const BUCKET = "sofec-sangomar-dropoff";
  const KEY = `data/monthly assessments/archive/2024-04/${fignum}.png`;
  const creds = new AWS.Credentials(accessKeyId, secretAccessKey,null)
  const client = new S3Client({credentials: creds,
    region:"us-east-2"});
  //console.log(client.config);
  const command = new GetObjectCommand({ Bucket: BUCKET, Key: KEY });
return getSignedUrl(client, command, { expiresIn: 3600 });
};
const createPresignedUrlWithClientCSV = (csvname) => {
  const REGIONN = "us-east-2";
  const BUCKET = "sofec-sangomar-dropoff";
  const KEY = `data/monthly assessments/monthly csvs/current/${csvname}.csv`;
  const creds = new AWS.Credentials(accessKeyId, secretAccessKey,null)
  const client = new S3Client({credentials: creds,
    region:"us-east-2"});
  //console.log(client.config);
  const command = new GetObjectCommand({ Bucket: BUCKET, Key: KEY });
return getSignedUrl(client, command, { expiresIn: 3600 });
};
const createPresignedUrlWithClientCSVArchive = ({month,csvname}) => {
  const REGIONN = "us-east-2";
  const BUCKET = "sofec-sangomar-dropoff";
  const KEY = `data/monthly assessments/monthly csvs/archive/${month}/${csvname}.csv`;
  const creds = new AWS.Credentials(accessKeyId, secretAccessKey,null)
  const client = new S3Client({credentials: creds,
    region:"us-east-2"});
  //console.log(client.config);
  const command = new GetObjectCommand({ Bucket: BUCKET, Key: KEY });
return getSignedUrl(client, command, { expiresIn: 3600 });
};
const createPresignedUrlWithClientPDFArchive = (month) => {
  const REGIONN = "us-east-2";
  const BUCKET = "sofec-sangomar-dropoff";
  const KEY = `data/monthly assessments/monthly reports/4008-RPT-0003 (${month}) - Sangomar Remote Monitoring Monthly Report.pdf`;
  const creds = new AWS.Credentials(accessKeyId, secretAccessKey,null)
  const client = new S3Client({credentials: creds,
    region:"us-east-2"});
  //console.log(client.config);
  const command = new GetObjectCommand({ Bucket: BUCKET, Key: KEY });
return getSignedUrl(client, command, { expiresIn: 3600 });
};



export const S3imagesModule = async (fignum) => {
    const finaldata = await createPresignedUrlWithClient(fignum)
    //console.log( finaldata)
  return (
    `${finaldata}`
  )
}
export const S3imagesModule2 = async (fignum) => {
  //console.log('fig',fignum)
  const finaldata = await createPresignedUrlWithClient2(fignum)
  //console.log( finaldata)
return (
  `${finaldata}`
)
}
export const S3CSVModule = async (csvname) => {
  const finaldata = await createPresignedUrlWithClientCSV(csvname)
  //console.log('csvname', finaldata)
return (
  finaldata
)
}

export const S3CSVModuleArchive = async ({month,csvname}) => {
  const finaldata = await createPresignedUrlWithClientCSVArchive({month,csvname})
  //console.log('csvname', finaldata)
return (
  finaldata
)
}
export const S3PDFModuleArchive = async (month) => {
  const finaldata = await createPresignedUrlWithClientPDFArchive(month)
  //console.log('csvname', finaldata)
return (
  finaldata
)
}
