import React , {useEffect,useState} from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import "../../styles.css";
import {Aws_controller} from '../../components';;


const tpmsParms2 = ['IRON','COPPER','TIN','LEAD','CHROMIUM','NICKEL','ALUMINUM','TITANIUM','SILVER','CALCIUM','MAGNESIUM','ZINC','PHOSPHORUS','BARIUM','MOLYBDENUM',
                    'ANTIMONY','H2O','SILICON','SODIUM','BORON','POTASSIUM','VANADIUM','HOURS_MILES','LITHIUM','PQ_INDEX'];
const locs = ['FWD', 'STBD','DRUM','AFT','PORT']


const MeterBar = ({
  id,
  value,
  ppmvalue,
  ledTotal,
  orientation,
  ranges,
  height = "20px"
}) => {
  const leds = Array.from(Array(ledTotal).keys());

  const orientationAsDirection =
    orientation === "vertical" ? "column-reverse" : "row";

  const getLEDColorForIndex = (index) => {
    const matchingRange = ranges.filter((range) => {
      return index >= range.min && index <= range.max;
    });

    return matchingRange[0];
  };

  return (
    <div
      style={{
        height:height,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: orientationAsDirection,
        border: "solid 1px black",
        padding: "0px"
      }}
    >
      <div className="flex w-3/12 justify-start overflow-hidden" style={{height:height}}>
      {id}
      </div>

      <div className="flex w-7/12 justify-start" style={{height:height}}>
        {leds.map((led, idx) => {
          const ledRange = getLEDColorForIndex(idx);

          //console.log(ledRange);
          return (
            <span
              style={{

                display: "flex",
                minHeight: "100%",
                background: ledRange?.color ?? "grey",
                border: '1px solid #0E0E0E',
                flexWrap:'wrap',
                flexGrow: 1,
                opacity: value >= idx ? 1 : 0.3
              }}
            ></span>
          );
        })}
      </div>
      
      <div className="flex w-2/12 justify-end overflow-hidden text-green-400" style={{height:height}}>
        {ppmvalue} ppm
      </div>

    </div>
  );
};

///////////////////////////////

  


// AWS PULL //
const statement = "SELECT * FROM dev.public.grease order by sample_date ASC;";
const thedata = await Aws_controller(statement)
//////////////


const MeterBarCluster = ({id , loc, dadate}) => {
  const [info, setInfo] = useState(thedata)
  const [POSITION, setPosition] = useState('');
  const [TIMESTAMP, setTimestamp] = useState('');
  const { currentColor } = useStateContext();
  //console.log('location: ', loc)
  
  //.. AWS PULL ..//
  //Set AWS data
  useEffect(() =>{setInfo(thedata)}, [thedata]);
  //const result = info.length
  //console.log('The Output:', info)
  let Wearpoints=[]
  let Addpoints=[]
  let Conpoints=[]


  //WEAR METALS
  for (let i = 0; i < info.length; i++) {
    let Wpoint = {};
    Wpoint["POSITION"] = info[i][1].stringValue;
    Wpoint["TIMESTAMP"] = info[i][4].stringValue;
    Wpoint['IRON'] = info[i][5].doubleValue;
    Wpoint['COPPER'] = info[i][6].doubleValue;
    Wpoint['TIN'] = info[i][7].doubleValue;
    Wpoint['LEAD'] = info[i][8].doubleValue;
    Wpoint['CHROMIUM'] = info[i][9].doubleValue;
    Wpoint['NICKEL'] = info[i][10].doubleValue;
    Wpoint['ALUMINUM'] = info[i][11].doubleValue;
    Wpoint['TITANIUM'] = info[i][12].doubleValue;
    Wpoint['SILVER'] = info[i][13].doubleValue;
    Wearpoints.push(Wpoint);}
    //console.log('The OutputWear:', Wearpoints)
    ////////////////// 
    const wears = Wearpoints.filter(para => para.POSITION.includes(loc) && para.TIMESTAMP.includes(dadate));
    //console.log('The OutputWearsNew2:', wears)
    //////////////////
  //ADDITIVE PARTICLES
  for (let i = 0; i < info.length; i++) {
    let Apoint = {};
    Apoint["POSITION"] = info[i][1].stringValue;
    Apoint["TIMESTAMP"] = info[i][4].stringValue;
    Apoint['CALCIUM'] = info[i][14].doubleValue;
    Apoint['MAGNESIUM'] = info[i][15].doubleValue;
    Apoint['ZINC'] = info[i][16].doubleValue;
    Apoint['PHOSPHORUS'] = info[i][17].doubleValue;
    Apoint['BARIUM'] = info[i][18].doubleValue;
    Apoint['MOLYBDENUM'] = info[i][19].doubleValue;
    Apoint['ANTIMONY'] = info[i][20].doubleValue;
    Apoint['LITHIUM'] = info[i][28].longValue;
    Addpoints.push(Apoint);}
    //console.log('The OutputADD:', Addpoints)
    ////////////////// 
    const adds = Addpoints.filter(para => para.POSITION.includes(loc) && para.TIMESTAMP.includes(dadate));
    //console.log('The OutputAddsNew2:', adds)
    //////////////////
  //CONTIMINATE PARTICLES
  for (let i = 0; i < info.length; i++) {
    let Cpoint = {};
    Cpoint["POSITION"] = info[i][1].stringValue;
    Cpoint["TIMESTAMP"] = info[i][4].stringValue;
    Cpoint['SILICON'] = info[i][22].doubleValue;
    Cpoint['SODIUM'] = info[i][23].doubleValue;
    Cpoint['BORON'] = info[i][24].doubleValue;
    Cpoint['POTASSIUM'] = info[i][25].doubleValue;
    Cpoint['VANADIUM'] = info[i][26].doubleValue;
    Conpoints.push(Cpoint);}
    //console.log('The OutputConn:', Conpoints)
    ////////////////// 
    const conns = Conpoints.filter(para => para.POSITION.includes(loc) && para.TIMESTAMP.includes(dadate));
    //console.log('The OutputConnNew2:', conns)
  /////////////////////////////////////////
  const setParticles= (idd) => {
    if (idd === 'wear') {
      const particles = [
        { label: 'Iron (Fe)', valueLed: wears[0].IRON , activeLeds: Math.round(wears[0].IRON/533.32) || 0, numLeds: 50, maxg: 19, miny: 20,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Copper (Cu)', valueLed: wears[0].COPPER ,activeLeds: Math.round(wears[0].COPPER/1.33) || 0,numLeds: 50 , maxg: 18, miny: 19,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Tin (Sn)', valueLed: wears[0].TIN , activeLeds: Math.round(wears[0].TIN/1.06) || 0, numLeds: 50 , maxg: 18, miny: 19,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Lead (Pb)', valueLed: wears[0].LEAD , activeLeds: Math.round(wears[0].LEAD/1.33) || 0, numLeds: 50 , maxg: 18, miny: 19,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Chromium (Cr)', valueLed: wears[0].CHROMIUM , activeLeds:Math.round( wears[0].CHROMIUM/0.8) || 0, numLeds: 50 , maxg: 18, miny: 19,maxy: 36,minr:37 ,maxr:50 },
        { label: 'Nickel (Ni)', valueLed: wears[0].NICKEL , activeLeds: Math.round(wears[0].NICKEL/0.532) || 0, numLeds: 50 , maxg: 17, miny: 18,maxy: 36,minr:37 ,maxr:50 },
        { label: 'Aluminum (Al))', valueLed: wears[0].ALUMINUM , activeLeds: Math.round(wears[0].ALUMINUM/0.8) || 0, numLeds: 50 , maxg: 18, miny: 19,maxy: 36,minr:37 ,maxr:50 },
        { label: 'Titanium (Ti)', valueLed: wears[0].TITANIUM , activeLeds: Math.round(wears[0].TITANIUM/0.532) || 0, numLeds: 50 , maxg: 17, miny: 18,maxy: 36,minr:37 ,maxr:50 },
        { label: 'Silver (Ag)', valueLed: wears[0].SILVER , activeLeds: Math.round(wears[0].SILVER/0.532) || 0, numLeds: 50 , maxg: 17, miny: 18,maxy: 36,minr:37 ,maxr:50 }
      ];
      return particles
    } else if (idd === 'additive') {
      const particles = [
        { label: 'Calcium (Ca)', valueLed: adds[0].CALCIUM , activeLeds: Math.round(adds[0].CALCIUM/40) || 0,numLeds: 50 , maxg: 12, miny: 13,maxy: 24,minr:25 ,maxr:50 },
        { label: 'Magnesium (Mg)', valueLed: adds[0].MAGNESIUM , activeLeds: Math.round(adds[0].MAGNESIUM/0.3) || 0,numLeds: 50 , maxg: 17, miny: 18,maxy: 35,minr:36 ,maxr:50 },
        { label: 'Zinc (Zn)', valueLed: adds[0].ZINC , activeLeds: Math.round(adds[0].ZINC/12) || 0,numLeds: 50 , maxg: 24, miny: 25,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Phosphorus (P)', valueLed: adds[0].PHOSPHORUS , activeLeds: Math.round(adds[0].PHOSPHORUS/2) || 0,numLeds: 50 , maxg: 22, miny: 23,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Barium (Ba)', valueLed: adds[0].BARIUM , activeLeds: Math.round(adds[0].BARIUM/0.2) || 0,numLeds: 50 , maxg: 20, miny: 21,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Molybdenum (Mo)', valueLed: adds[0].MOLYBDENUM , activeLeds: Math.round(adds[0].MOLYBDENUM/0.2) || 0,numLeds: 50 , maxg: 20, miny: 21,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Antimony (Sb)', valueLed: adds[0].ANTIMONY , activeLeds: Math.round(adds[0].ANTIMONY/0.2) || 0,numLeds: 50 , maxg: 20, miny: 21,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Lithium (Li)', valueLed: adds[0].LITHIUM , activeLeds: Math.round(adds[0].LITHIUM/100) || 0,numLeds: 50 , maxg: 25, miny: 26,maxy: 36,minr:37 ,maxr:50 },
      ];
      return particles
    } else if (idd === 'contaminate') {
      //Just do all the division line by line figure out a factor for all and do the division in line 
      // Figure out division operation 
      const particles = [
        { label: 'Silicon (Si)', valueLed: conns[0].SILICON ,activeLeds: Math.round(conns[0].SILICON/5.32) || 0, numLeds: 50 , maxg: 18, miny: 19,maxy: 37,minr:38 ,maxr:50 },
        { label: 'Sodium (Na)', valueLed: conns[0].SODIUM  ,activeLeds: Math.round(conns[0].SODIUM/4) || 0,numLeds: 50 , maxg: 24, miny: 25, maxy:37 ,minr:38 ,maxr:50 },
        { label: 'Boron (B)', valueLed: conns[0].BORON  ,activeLeds: Math.round(conns[0].BORON/5.32) || 0, numLeds: 50 , maxg: 28,  miny: 29, maxy:37 ,minr:38,maxr:50 },
        { label: 'Potassium (K)', valueLed: conns[0].POTASSIUM ,activeLeds: Math.round(conns[0].POTASSIUM/1.33) || 0, numLeds: 50 , maxg: 18, miny:19 ,maxy: 37 ,minr:38 ,maxr: 50 },
        { label: 'Vanadium (V)',  valueLed: conns[0].VANADIUM ,activeLeds: Math.round(conns[0].VANADIUM/0.532) || 0, numLeds: 50 , maxg: 17, miny: 18 ,maxy: 36,minr:37 ,maxr:50 }
      ];
      return particles
    } else {
      const particles = [
        { label: 'N/A', activeLeds: 0, numLeds: 50},
        { label: 'N/A', activeLeds: 0, numLeds: 50},
        { label: 'N/A', activeLeds: 0, numLeds: 50},
        { label: 'N/A', activeLeds: 0, numLeds: 50},
        { label: 'N/A', activeLeds: 0, numLeds: 50},
      ]
      return particles
    };

  }
  const ticles = setParticles(id)



  return (
    <div style={{ textAlign: "left" }} id={id}>        

    {ticles.map((item, index) => (
        <div key={index}>
                <MeterBar
                  id ={item.label}
                  height={"30px"}
                  value={item.activeLeds}
                  ppmvalue={item.valueLed}
                  ledTotal={item.numLeds}
                  orientation="horizontal"
                  ranges={[
                    { min: 0, max: item.maxg, color: "green" },
                    { min: item.miny, max: item.maxy, color: "orange" },
                    { min: item.minr, max: item.maxr, color: "red" }
                  ]}
                />
        </div>
      ))}  
      



    </div>
  );
};
export default MeterBarCluster
