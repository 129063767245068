import React, {useState, useEffect} from "react";
import AWS, { Config } from 'aws-sdk';
import { S3Client, GetObjectCommand,ListObjectsV2Command  } from "@aws-sdk/client-s3";
import { S3imagesModule2} from '../components/index';
import { useStateContext } from '../contexts/ContextProvider';
import { Banner2} from '../components';
import { Header2 }  from '../components';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

const accessKeyIdAWS = process.env.REACT_APP_AWS_ACCESS_KEY_ID
const secretAccessKeyAWS = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

export const S3ListFolder = ({ onSelectionChange }) => {
    const [items, setItems] = useState([]);
    const { currentColor } = useStateContext();
    const [pos, setPos] = useState('2024-04');
    


    useEffect(() => {
      const listS3Folders = async () => {
        // Create an S3 client
        const s3Client = new S3Client({
            region: 'us-east-2',
            credentials: {
              accessKeyId: accessKeyIdAWS,
              secretAccessKey: secretAccessKeyAWS,
            },
          });
        // const creds = new AWS.Credentials('', '',null)
        // const s3Client = new S3Client({credentials: creds,
        //   region:"us-east-2"});
  
        // Define the S3 bucket and folder (prefix)
        const bucketName = "sofec-sangomar-dropoff";
        const folderPrefix = 'data/monthly assessments/archive/'; // Ensure this ends with a slash if it's a folder
  
      // Create the command to list objects
      const listObjectsCommand = new ListObjectsV2Command({
        Bucket: bucketName,
        Prefix: folderPrefix,
        Delimiter: '/', // Use delimiter to group objects by prefix
      });

      try {
        // Send the command to S3 and get the response
        const data = await s3Client.send(listObjectsCommand);

        // Extract common prefixes (i.e., folders)
        const folderList = data.CommonPrefixes.map((prefix) =>{
            const parts = prefix.Prefix.split('/')
            return parts[parts.length-2]
            });

        // Set the folders state with the list of folder prefixes
        setItems(folderList);
      } catch (err) {
        console.error('Error listing S3 folders:', err);
      }
    };

    listS3Folders();
  }, []);

  const handleChange = (event) => {
    const selectedItem = event.itemData;
    onSelectionChange(selectedItem);
  };

  //console.log('items:',items)
    return (
      <div>
        <div>
        <div className="m-0 md:m-10  p-2 rounded-3xl
                    bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                    border-slate-600 dark:border-white border-4">
          <div className='bg-white dark:bg-secondary-dark-bg flex flex-wrap w-full pl-2 pr-2 justify-center rounded-xl'>
              <div className='flex flex-wrap w-full align-middle rounded-2xl pr-0'>
                <div className="w-full flex items-center ">
                  <div className='flex flex-wrap w-11/12  align-middle justify-end rounded-2xl pr-0'>
                    Select DataSet:
                  </div>
                  <div className="flex flex-wrap w-1/12 m-0 md:m-2 mt-24 p-1 bg-slate-500 rounded-2xl">
                    <DropDownListComponent id="ddlelement" 
                    dataSource={items} 
                    placeholder={'2024-04'}
                    popupHeight="auto" 
                    onChange={e => setPos(e.target.value)}
                    change={handleChange}
                    />
                  </div>
                </div>
                
              </div>
          </div>
        </div>
        </div>
        {/* <div>
          <h3>Items in S3 Folder</h3>
          <ul>
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div> */}

      </div>
    );
  };
  
 

 export const S3ListFiles = ({mmyy}) => {
    const [items, setItems] = useState([]);
    const { currentColor } = useStateContext();

    useEffect(() => {
      const listS3Objects = async () => {
        // Create an S3 client
        const s3Client = new S3Client({
            region: 'us-east-2',
            credentials: {
              accessKeyId: accessKeyIdAWS,
              secretAccessKey: secretAccessKeyAWS,
            },
          });
        // Define the S3 bucket and folder (prefix)
        const bucketName = "sofec-sangomar-dropoff";
        const folderPrefix = `data/monthly assessments/archive/${mmyy}/`; // Ensure this ends with a slash if it's a folder
        //console.log('items:',folderPrefix)
     // Create the command to list objects
     const listObjectsCommand = new ListObjectsV2Command({
        Bucket: bucketName,
        Prefix: folderPrefix,
      });

      try {
        // Send the command to S3 and get the response
        const data = await s3Client.send(listObjectsCommand);

        // Set the items state with the keys of the objects
        setItems(data.Contents.map((item) => {
            const parts = item.Key.split('/')
            return parts[parts.length-1]
            }));
      } catch (err) {
        console.error('Error listing S3 objects:', err);
      }
      
    };

    listS3Objects();
  }, []);
  
  const list_1=[];
  const list_2=[];
  const list_3=[];
  const list_4=[];
  const list_5=[];

  for (let i = 0; i < items.length; i++) {
    if (items[i].includes('FIG_1')) {
        list_1.push(items[i]);
    }
    if (items[i].includes('FIG_2')) {
        list_2.push(items[i]);
    }
    if (items[i].includes('FIG_3')){
        list_3.push(items[i]);
    }
    if (items[i].includes('FIG_4')) {
        list_4.push(items[i]);
    }
    if (items[i].includes('FIG_5')) {
        list_5.push(items[i]);
    }}
  //console.log('lsit:',list_1[0])
  //useEffect(() => {setImage( S3imagesModule2('FIG_1-1'))},[])
    return (list_1
    //   <div>
    //      <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
    //          bg-white dark:bg-white dark:border-slate-600">
    //         <div>
    //             <Header2  title= "Section 1: StationKeeping Performance" thacolor= {currentColor}/>
    //         </div>
    //         <div className="flex m-2 flex-wrap gap-1 items-center">
    //         <img  src={image}  />


    //             <div className="flex m-2 flex-wrap w-full justify-center gap-1 items-center">
    //                 <div className="m-2 md:m-1 mt-4 p-2 md:p-3 border-slate-600 border-4 rounded-3xl
    //                 bg-white dark:bg-white dark:border-slate-600">
    //                     <img  src={image}  />
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //   </div>
    );

  };
  
