import React, {useEffect,useState} from 'react'
import { UseStateContext } from '../contexts/ContextProvider';
import AWS, { Config } from 'aws-sdk';
import { RedshiftDataClient, ExecuteStatementCommand, GetStatementResultCommand,DescribeStatementCommand } from "@aws-sdk/client-redshift-data";
import { DescribeClustersCommand }  from "@aws-sdk/client-redshift";
import { fromEnv } from "@aws-sdk/credential-provider-env";
//import {AWS} from 'aws-sdk'
import  { RedshiftClient }  from  "@aws-sdk/client-redshift";
import { consoleLogger } from '@influxdata/influxdb-client';
import { Result } from 'postcss';

function timeout(delay) {
  return new Promise( res => setTimeout(res, delay) );
}
function wait4client(dacaller) {
  return new Promise.resolve(dacaller).then(result=>console.log(result.$metadata.Id));
}


//   function FetchData1(cmd1) {
  
const fetchData = async (cmd1) => {
  try{
    const execution = await client.send(cmd1)
    //await timeout(1000)
    const idstrang = execution.$metadata.requestId
    const input2 = { // GetStatementResultRequest
      Id: idstrang,}
    //console.log("idstrangg:",input2)
    try{
      const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
      const value2 = await client.send(command2);
      //console.log('connect2:',value2)
      const resp2Recs = value2.Records.map((m)=>m)
      // console.log('Records',resp2Recs)
      return resp2Recs
       } catch (error){
        await timeout(1000)
        const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
        const value2 = await client.send(command2);
        //console.log('connect3:',value2)
        const resp2Recs = value2.Records.map((m)=>m)
        //console.log('Records:',resp2Recs)
        return resp2Recs
       }
    //await useEffect(() =>{setInfo(input2)}, []);

  } catch (error) {
    try{
      const execution = await client.send(cmd1)
      await timeout(1000)
      const idstrang = execution.$metadata.requestId
      const input2 = { // GetStatementResultRequest
        Id: idstrang,}
      //console.log("idstrangg:",input2)
      try{
        const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
        const value2 = await client.send(command2);
        //console.log('connect2:',value2)
        const resp2Recs = value2.Records.map((m)=>m)
        // console.log('Records',resp2Recs)
        return resp2Recs
         } catch (error){
          await timeout(1000)
          const command2 = new GetStatementResultCommand(input2)//.catch(e => { console.log('Problem:' , new DescribeStatementCommand(input2)) });
          const value2 = await client.send(command2);
          //console.log('connect3:',value2)
          const resp2Recs = value2.Records.map((m)=>m)
          //console.log('Records:',resp2Recs)
          return resp2Recs
         }
      //await useEffect(() =>{setInfo(input2)}, []);
  
    } catch (error) {
      // error handling.
      console.log('error hit')
      console.log(error)
      return null;
    }
  }
};





//console.log('1aa')
//console.log("asdas2:", process.env.REACT_APP_AWS_SECRET_ACCESS_KEY)
//console.log("asdas:", process.env.REACT_APP_AWS_ACCESS_KEY_ID)
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
const creds = new AWS.Credentials(accessKeyId, secretAccessKey, null)
const client= new RedshiftDataClient({credentials: creds,
region:"us-east-2"});


const Aws_controller = async (SQLstatment) => {
    
    const input1 = { // ExecuteStatementInput
        Sql: SQLstatment,  //"SELECT * FROM dev.public.ds_alims WHERE mean is not null limit 10;",
        ClusterIdentifier: "sofec-datawarehouse",
        Database: "dev", // required
        WithEvent: true,
      }; 

    //Execute Statement
    const command1 = new ExecuteStatementCommand(input1);
    const finaldata = fetchData(command1)
    //console.log('final data:',finaldata)

    //Set AWS data
    
    return (finaldata)
    
}

  export default Aws_controller
  



