import React, {useEffect,useState} from 'react'

//Swivel Board Stuff
import ReactiveButton from 'reactive-button';
import {Link} from 'react-router-dom'
import { SwivelPagesBoard } from '../data/dummy';

const BannerSwivelList = () => {
// Swivel Board Stuff //
  const [state, setState] = useState('idle');
  const onClickHandler2 = () => {
    setState('loading');
    // send an HTTP request
    setTimeout(() => {
      setState('success');
    }, 2000);
  };
  const buttonstyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div className='mt-0'>
    <div className='m-0 md:m-10 md-10 md:p-0 rounded-3xl
        bg-slate-200 dark:bg-slate-200  dark:text-slate-700
        border-slate-600  dark:border-white border-2'>
    <div className=" md:m-10 mt-24  md-10 md:p-10  rounded-3xl
                  bg-white dark:text-gray-200 dark:bg-secondary-dark-bg
                  border-slate-600 dark:border-white border-4">
        <div className="w-full">
          <div style={buttonstyle}>
            {SwivelPagesBoard.map((item)=> (
              <div style={{marginLeft: '.5rem'}}>
                <Link to={`/${item.name}`}>
                  <ReactiveButton
                    disabled={false}
                    shadow
                    rounded
                    
                    color='secondary'
                    
                    buttonState={state}
                    idleText={item.title}
                    loadingText="Loading"
                    successText="Done"
                    onClick={onClickHandler2}
                    height = {'60px'}
                  />
                </Link>
              </div>))
            }
          </div>
        </div>
      </div>
      <div className='w-full font-semibold text-xl text-center justify-center items-center'>
                <span>
                  Only Production 1 Swivel data available at this time.
                </span> 

            </div> 
        </div>
        </div>
  )
}

export default BannerSwivelList