import React from 'react'


// function timeout(delay) {
//   return new Promise( res => setTimeout(res, delay) );
// }

// const statement = "SELECT * FROM dev.public.ds_alims WHERE mean is not null limit 10;";

// // Set the AWS Region.
// // Hey Daniel:)
// //const client2 = new RedshiftDataClient({credentials: fromIni()});
// console.log(process.env.AWS_ACCESS_KEY_ID)
// //const configg = AWS.config.loadFromPath('./config.json');
// //console.log(config)




// // ################ //

// //var credentials = new AWS.SharedIniFileCredentials({profile: 'default'});
// var creds = new AWS.Credentials('', '',null)
// console.log("Access key:", creds)
// // AWS.config.credentials = credentials;
// // var AWS = require('aws-sdk')
// // AWS.config.getCredentials(function(err) {
// //   if (err) console.log(err.stack);
//   // credentials not loaded
// //   else {
// //      console.log("Access key:", AWS.config.credentials.accessKeyId);
// //    }
// //  });
// //const config = AWS.config.loadFromPath('./config.json');
// //var creds = new AWS.Credentials({
// //  accessKeyId: '', secretAccessKey: ''
// //});
// /////////////////////////////
// //const tester = AWS.config.credentials.accessKeyId.toString
// const client= new RedshiftDataClient({credentials: creds,
//   region:"us-east-2"});
// const input1 = { // BatchExecuteStatementInput
//   Sql:"SELECT * FROM dev.public.ds_alims WHERE mean is not null limit 10;",
//   ClusterIdentifier: "sofec-datawarehouse",
//   //DbUser: 'IAM:sangomar-dev-svc-account',
//   Database: "dev", // required
//   StatementName: "STRING_VALUE",
//   WithEvent: true,
//   //WorkgroupName: "STRING_VALUE",
//   //ClientToken: "STRING_VALUE",
// }; 
// console.log(client)
// console.log('a')

// //Execute Statement
// const command1 = new ExecuteStatementCommand(input1);
// //const response = await client.send(command);
// console.log(command1)
// const response1 = await client.send(command1);
// await timeout(1000); //for 1 sec delay
// console.log('The Response:', response1)
// console.log('b')
// const respID = response1.Id
// console.log('The Response ID:', respID)
// //Set Input2
// const input2 = { // GetStatementResultRequest
//   Id: respID // required
//   //NextToken: "STRING_VALUE",
// };

// //Describe Statement
// const command3 = new DescribeStatementCommand(input2);
// const response3 = await client.send(command3);
// console.log('The Command3:',command3)

// console.log('The Response3:', response3)

// //Get Execute Statement Result

// const command2 = new GetStatementResultCommand(input2);
// console.log('The Command2:',command2)
// const response2 = await client.send(command2);
// console.log('The Response2:', response2)
// const resp2Recs = response2.Records
// const r2rMap = resp2Recs.flat(m => m)
// console.log('The Response Records:', resp2Recs)
// console.log('The Flattened Records:', r2rMap)
//{resp2Recs}
//{resp2Recs[0][0].stringValue}
const statement = "SELECT * FROM dev.public.east_north WHERE timestamp >= DATEADD(MONTH, -1, '2022-05-31 00:00:00') ORDER BY timestamp ASC;";
//const dadata = await Aws_controller(statement)

const Blanktestpage = () => {

}

export default Blanktestpage
